import AppConstants from '../constants/AppConstants'
import Dispatcher from '../dispatcher'

const Actions = {
  appStart: (item) => {
    Dispatcher.handleAppAction({
      type: AppConstants.APP_START,
      item
    })
  },
  defineClient: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.DEFINE_CLIENT,
      item: undefined
    })
  },
  updatePath: (path) => {
    Dispatcher.handleAppAction({
      type: AppConstants.UPDATE_PATH,
      item: path
    })
  },
  expandHeader: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.EXPAND_HEADER,
      item: undefined
    })
  },
  collapseHeader: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.COLLAPSE_HEADER,
      item: undefined
    })
  },
  openSearch: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.OPEN_SEARCH,
      item: undefined
    })
  },
  closeSearch: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.CLOSE_SEARCH,
      item: undefined
    })
  },
  openBag: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.OPEN_BAG,
      item: undefined
    })
  },
  closeBag: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.CLOSE_BAG,
      item: undefined
    })
  },
  openBurger: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.OPEN_BURGER,
      item: undefined
    })
  },
  closeBurger: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.CLOSE_BURGER,
      item: undefined
    })
  },
  openAccount: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.OPEN_ACCOUNT,
      item: undefined
    })
  },
  closeAccount: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.CLOSE_ACCOUNT,
      item: undefined
    })
  },
  addToBag: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.ADD_TO_BAG,
      item: data
    })
  },
  removeFromBag: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.REMOVE_FROM_BAG,
      item: data
    })
  },
  updateBagProductSize: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.UPDATE_BAG_PRODUCT_SIZE,
      item: data
    })
  },
  openNotification: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.OPEN_NOTIFICATION,
      item: data
    })
  },
  closeNotification: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.CLOSE_NOTIFICATION,
      item: undefined
    })
  },
  openCookiesPanel: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.OPEN_COOKIES_PANEL,
      item: undefined
    })
  },
  closeCookiesPanel: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.CLOSE_COOKIES_PANEL,
      item: undefined
    })
  },
  openLockedMessagesPanel: (dom, color=`dark`) => {
    Dispatcher.handleAppAction({
      type: AppConstants.OPEN_LOCKED_MESSAGES_PANEL,
      item: { dom, color }
    })
  },
  closeLockedMessagesPanel: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.CLOSE_LOCKED_MESSAGES_PANEL,
      item: undefined
    })
  },
  openPopupLoginPanel: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.OPEN_POPUP_LOGIN_PANEL,
      item: undefined
    })
  },
  closePopupLoginPanel: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.CLOSE_POPUP_LOGIN_PANEL,
      item: undefined
    })
  },
  addToFavorites: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.ADD_TO_FAVORITES,
      item: data
    })
  },
  addToRecentlyViewed: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.ADD_TO_RECENTLY_VIEWED,
      item: data
    })
  },
  removeFromFavorites: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.REMOVE_FROM_FAVORITES,
      item: data
    })
  },
  register: (user) => {
    Dispatcher.handleAppAction({
      type: AppConstants.REGISTER,
      item: user
    })
  },
  updateCustomer: (user) => {
    Dispatcher.handleAppAction({
      type: AppConstants.UPDATE_CUSTOMER,
      item: user
    })
  },
  login: (user) => {
    Dispatcher.handleAppAction({
      type: AppConstants.LOGIN,
      item: user
    })
  },
  logout: (silenced=false) => {
    Dispatcher.handleAppAction({
      type: AppConstants.LOGOUT,
      item: silenced
    })
  },
  pay: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.PAY,
      item: undefined
    })
  },
  sendContactFormRequest: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.SEND_CONTACT_FORM_REQUEST,
      item: data
    })
  },
  subscribeToNewsletter: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.SUBSCRIBE_TO_NEWSLETTER,
      item: data
    })
  },
  moveFavoritesToBag: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.MOVE_FAVORITES_TO_BAG,
      item: data
    })
  },
  startLoadingPage: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.START_LOADING_PAGE,
      item: undefined
    })
  },
  finishLoadingPage: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.FINISH_LOADING_PAGE,
      item: undefined
    })
  },
  closeAllPanels: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.CLOSE_ALL_PANELS,
      item: undefined
    })
  },
  setCustomer: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.SET_CUSTOMER,
      item: data
    })
  },
  setStripeTokenToCustomer: (token) => {
    Dispatcher.handleAppAction({
      type: AppConstants.SET_STRIPE_TOKEN_TO_CUSTOMER,
      item: token
    })
  },
  resetCustomer: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.RESET_CUSTOMER,
      item: data
    })
  },
  reloadUserFromServer: (id) => {
    Dispatcher.handleAppAction({
      type: AppConstants.RELOAD_USER_FROM_SERVER,
      item: id
    })
  },
  stripeHandleCardAction: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.STRIPE_HANDLE_CARD_ACTION,
      item: data
    })
  },
  setStripePaymentIntent: (id) => {
    Dispatcher.handleAppAction({
      type: AppConstants.SET_STRIPE_PAYMENT_INTENT,
      item: id
    })
  },
  subscribeToWaitlist: (email, size, title, sku, style_id) => {
    Dispatcher.handleAppAction({
      type: AppConstants.SUBSCRIBE_TO_WAITLIST,
      item: { email: email, product_ids: [size.variation_id], size: size.id, title, sku, style_id }
    })
  },
  applyPromoCode: (data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.APPLY_PROMO_CODE,
      item: data
    })
  },
  startPaymentProcess: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.START_PAYMENT_PROCESS,
      item: undefined
    })
  },
  finishPaymentProcess: (paymentStatus, data) => {
    Dispatcher.handleAppAction({
      type: AppConstants.FINISH_PAYMENT_PROCESS,
      item: { status: paymentStatus, data }
    })
  },
  customerStatusChanged: () => {
    Dispatcher.handleAppAction({
      type: AppConstants.CUSTOMER_STATUS_CHANGED,
      item: undefined
    })
  }
}

export default Actions
