/* globals window CustomEvent */
import 'whatwg-fetch'
import 'intersection-observer'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import WindowActions from 'WindowActions'

const getPageType = (name) => {
  if (name.indexOf('checkout') > -1) return AppConstants.CHECKOUT
  else return AppConstants.HOME
}

const updatePath = (location) => {
  setTimeout(WindowActions.resize, 0, window.innerWidth, window.innerHeight)
  const path = location.pathname
  const name = path[0] == '/' ? path.substr(1) : path
  const type = getPageType(name)
  setTimeout(AppActions.updatePath, 0, { path, name, type })
}

export const onRouteUpdate = ({ location }) => {
  updatePath(location)
}

export const onClientEntry = () => {
  setTimeout(AppActions.defineClient, 0)
  setTimeout(WindowActions.resize, 0, window.innerWidth, window.innerHeight)
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    console.log(`👍 IntersectionObserver is polyfilled`)
    require(`intersection-observer`)
  }
  require('Utils/localStorage').polyfill()
}
