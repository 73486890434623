const Constants = {
  WINDOW_RESIZE: 'WINDOW_RESIZE',
  LANDSCAPE: 'LANDSCAPE',
  PORTRAIT: 'PORTRAIT',

  ENABLE_SCROLL: 'ENABLE_SCROLL',
  DISABLE_SCROLL: 'DISABLE_SCROLL',

  FORWARD: 'FORWARD',
  BACKWARD: 'BACKWARD',

  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  UP: 'UP',
  DOWN: 'DOWN'
}

export default Constants
