// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-simple-page-index-js": () => import("/opt/build/repo/src/templates/SimplePage/index.js" /* webpackChunkName: "component---src-templates-simple-page-index-js" */),
  "component---src-templates-editorial-page-index-js": () => import("/opt/build/repo/src/templates/EditorialPage/index.js" /* webpackChunkName: "component---src-templates-editorial-page-index-js" */),
  "component---src-templates-product-page-index-js": () => import("/opt/build/repo/src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */),
  "component---src-templates-category-page-index-js": () => import("/opt/build/repo/src/templates/CategoryPage/index.js" /* webpackChunkName: "component---src-templates-category-page-index-js" */),
  "component---src-components-my-account-forgot-password-index-js": () => import("/opt/build/repo/src/components/MyAccount/ForgotPassword/index.js" /* webpackChunkName: "component---src-components-my-account-forgot-password-index-js" */),
  "component---src-components-my-account-login-index-js": () => import("/opt/build/repo/src/components/MyAccount/Login/index.js" /* webpackChunkName: "component---src-components-my-account-login-index-js" */),
  "component---src-components-my-account-details-index-js": () => import("/opt/build/repo/src/components/MyAccount/Details/index.js" /* webpackChunkName: "component---src-components-my-account-details-index-js" */),
  "component---src-components-my-account-favorites-index-js": () => import("/opt/build/repo/src/components/MyAccount/Favorites/index.js" /* webpackChunkName: "component---src-components-my-account-favorites-index-js" */),
  "component---src-components-my-account-order-history-index-js": () => import("/opt/build/repo/src/components/MyAccount/OrderHistory/index.js" /* webpackChunkName: "component---src-components-my-account-order-history-index-js" */),
  "component---src-components-my-account-addresses-index-js": () => import("/opt/build/repo/src/components/MyAccount/Addresses/index.js" /* webpackChunkName: "component---src-components-my-account-addresses-index-js" */),
  "component---src-components-my-account-newsletters-index-js": () => import("/opt/build/repo/src/components/MyAccount/Newsletters/index.js" /* webpackChunkName: "component---src-components-my-account-newsletters-index-js" */),
  "component---src-components-checkout-login-index-js": () => import("/opt/build/repo/src/components/Checkout/Login/index.js" /* webpackChunkName: "component---src-components-checkout-login-index-js" */),
  "component---src-components-checkout-billing-index-js": () => import("/opt/build/repo/src/components/Checkout/Billing/index.js" /* webpackChunkName: "component---src-components-checkout-billing-index-js" */),
  "component---src-templates-game-editorial-page-index-js": () => import("/opt/build/repo/src/templates/GameEditorialPage/index.js" /* webpackChunkName: "component---src-templates-game-editorial-page-index-js" */),
  "component---src-templates-red-page-index-js": () => import("/opt/build/repo/src/templates/RedPage/index.js" /* webpackChunkName: "component---src-templates-red-page-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bag-js": () => import("/opt/build/repo/src/pages/bag.js" /* webpackChunkName: "component---src-pages-bag-js" */),
  "component---src-pages-components-test-da-4447393028-js": () => import("/opt/build/repo/src/pages/components-test-da4447393028.js" /* webpackChunkName: "component---src-pages-components-test-da-4447393028-js" */),
  "component---src-pages-components-test-da-44-f-114-js": () => import("/opt/build/repo/src/pages/components-test-da44f114.js" /* webpackChunkName: "component---src-pages-components-test-da-44-f-114-js" */),
  "component---src-pages-components-test-da-65656565-js": () => import("/opt/build/repo/src/pages/components-test-da65656565.js" /* webpackChunkName: "component---src-pages-components-test-da-65656565-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-editorial-js": () => import("/opt/build/repo/src/pages/editorial.js" /* webpackChunkName: "component---src-pages-editorial-js" */),
  "component---src-pages-favorites-js": () => import("/opt/build/repo/src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("/opt/build/repo/src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-search-js": () => import("/opt/build/repo/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-size-guide-js": () => import("/opt/build/repo/src/pages/size-guide.js" /* webpackChunkName: "component---src-pages-size-guide-js" */),
  "component---src-pages-stores-js": () => import("/opt/build/repo/src/pages/stores.js" /* webpackChunkName: "component---src-pages-stores-js" */)
}

