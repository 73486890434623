const sassVars = require('!!sass-variables-loader!../../styles/global/_variables.scss') // import the variables from scss

const api_env = () => {
  if (process.env.GATSBY_ENV === 'production') return 'custom'
  else return 'development'
}

export default {
  APP_START: 'APP_START',
  UPDATE_PATH: 'UPDATE_PATH',
  DEFINE_CLIENT: 'DEFINE_CLIENT',
  START_LOADING_PAGE: 'START_LOADING_PAGE',
  FINISH_LOADING_PAGE: 'FINISH_LOADING_PAGE',
  EXPAND_HEADER: 'EXPAND_HEADER',
  COLLAPSE_HEADER: 'COLLAPSE_HEADER',
  OPEN_SEARCH: 'OPEN_SEARCH',
  CLOSE_SEARCH: 'CLOSE_SEARCH',
  OPEN_BAG: 'OPEN_BAG',
  CLOSE_BAG: 'CLOSE_BAG',
  UPDATE_BAG_PRODUCT_SIZE: 'UPDATE_BAG_PRODUCT_SIZE',
  OPEN_BURGER: 'OPEN_BURGER',
  CLOSE_BURGER: 'CLOSE_BURGER',
  OPEN_ACCOUNT: 'OPEN_ACCOUNT',
  CLOSE_ACCOUNT: 'CLOSE_ACCOUNT',
  OPEN_COOKIES_PANEL: 'OPEN_COOKIES_PANEL',
  CLOSE_COOKIES_PANEL: 'CLOSE_COOKIES_PANEL',
  OPEN_LOCKED_MESSAGES_PANEL: 'OPEN_LOCKED_MESSAGES_PANEL',
  CLOSE_LOCKED_MESSAGES_PANEL: 'CLOSE_LOCKED_MESSAGES_PANEL',
  OPEN_POPUP_LOGIN_PANEL: 'OPEN_POPUP_LOGIN_PANEL',
  CLOSE_POPUP_LOGIN_PANEL: 'CLOSE_POPUP_LOGIN_PANEL',
  OPEN_NOTIFICATION: 'OPEN_NOTIFICATION',
  CLOSE_NOTIFICATION: 'CLOSE_NOTIFICATION',
  ADD_TO_BAG: 'ADD_TO_BAG',
  REMOVE_FROM_BAG: 'REMOVE_FROM_BAG',
  ADD_TO_FAVORITES: 'ADD_TO_FAVORITES',
  REMOVE_FROM_FAVORITES: 'REMOVE_FROM_FAVORITES',
  ADD_TO_RECENTLY_VIEWED: 'ADD_TO_RECENTLY_VIEWED',
  SEND_CONTACT_FORM_REQUEST: 'SEND_CONTACT_FORM_REQUEST',
  SUBSCRIBE_TO_NEWSLETTER: 'SUBSCRIBE_TO_NEWSLETTER',
  SUBSCRIBE_TO_WAITLIST: 'SUBSCRIBE_TO_WAITLIST',
  MOVE_FAVORITES_TO_BAG: 'MOVE_FAVORITES_TO_BAG',
  CLOSE_ALL_PANELS: 'CLOSE_ALL_PANELS',
  APPLY_PROMO_CODE: 'APPLY_PROMO_CODE',
  START_PAYMENT_PROCESS: 'START_PAYMENT_PROCESS',
  FINISH_PAYMENT_PROCESS: 'FINISH_PAYMENT_PROCESS',
  REGISTER: 'REGISTER',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  CUSTOMER_STATUS_CHANGED: 'CUSTOMER_STATUS_CHANGED',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  RESET_CUSTOMER: 'RESET_CUSTOMER',
  SET_CUSTOMER: 'SET_CUSTOMER',
  RELOAD_USER_FROM_SERVER: 'RELOAD_USER_FROM_SERVER',
  SET_STRIPE_TOKEN_TO_CUSTOMER: 'SET_STRIPE_TOKEN_TO_CUSTOMER',
  SET_STRIPE_PAYMENT_INTENT: 'SET_STRIPE_PAYMENT_INTENT',
  STRIPE_HANDLE_CARD_ACTION: 'STRIPE_HANDLE_CARD_ACTION',
  HOME: 'HOME',
  CHECKOUT: 'CHECKOUT',

  LOADING_PAGE_TIMEOUT: Math.floor(Math.random() * 300) + 500,

  BAG: 'BAG_J',
  COOKIES: 'COOKIES',
  FAVORITES: 'FAVORITES_J',
  RECENTLY_VIEWED: 'RECENTLY_VIEWED_J',
  CUSTOMER: 'CUSTOMER',
  GUEST_CUSTOMER: 'GUEST_CUSTOMER',

  BILLING: 'BILLING',
  SHIPPING: 'SHIPPING',
  PAY: 'PAY',

  LOCALE: 'en-US',
  CURRENCY: 'EUR',

  POST_OPTIONS: { method: 'POST', headers: { 'Content-Type': 'application/json; charset=utf-8' } },
  GET_OPTIONS: { method: 'GET', headers: { 'Content-Type': 'application/json; charset=utf-8' } },

  OVERALL_MARGIN: parseInt(sassVars.OVERALL_MARGIN, 10),
  HEADER_HEIGHT: parseInt(sassVars.HEADER_HEIGHT, 10),
  HEADER_POS_MINIMIZE: parseInt(sassVars.HEADER_POS_MINIMIZE, 10),
  BACKGROUND_BUTTON_HEIGHT: parseInt(sassVars.BACKGROUND_BUTTON_HEIGHT, 10),

  COLOR_SUCCESS: sassVars.SUCCESS,
  COLOR_ERROR: sassVars.ERROR,
  COLOR_WARNING: sassVars.WARNING,
  COLOR_BLUE: sassVars.BLUE,

  MQ_XSMALL: parseInt(sassVars.MQ_XSMALL, 10),
  MQ_SMALL: parseInt(sassVars.MQ_SMALL, 10),
  MQ_MEDIUM: parseInt(sassVars.MQ_MEDIUM, 10),
  MQ_LARGE: parseInt(sassVars.MQ_LARGE, 10),
  MQ_XLARGE: parseInt(sassVars.MQ_XLARGE, 10),
  MQ_XXLARGE: parseInt(sassVars.MQ_XXLARGE, 10),

  DESKTOP: 'DESKTOP',
  TABLET: 'TABLET',
  MOBILE: 'MOBILE',

  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',

  HISTORY_EXITING_EVENT_TYPE: 'history::exiting',
  PAGE_TRANSITION_DELAY: 300,

  STRIPE_PUBLIC_KEY: process.env.GATSBY_STRIPE_PUBLIC_KEY,
  BASE_URL: process.env.GATSBY_ENV === 'development' ? `${typeof window !== `undefined` && window.location ? window.location.protocol : `http:`}//localhost:8000/` : (process.env.GATSBY_ENV === 'production' ? `https://stellaluna.co/` : `https://staging.stellaluna.co/`),
  API_URL: `https://${process.env.GATSBY_API_BASE_URL}/wp-json/${api_env()}/v1/`,
  S3_URL: `https://${process.env.GATSBY_S3_URL}/`,
  CLOUDFRONT_CONTENT_URL: `https://${process.env.GATSBY_S3_URL}/wp-content/uploads/`
}
